$(function () {
  let windowWidth = $(window).width();
  if (windowWidth > 767) {
    // top_business
    $('.business2__link').on('mouseenter', function () {
      $('.business2__list__img').removeClass('is-show');
      $(this).parent().find('.business2__list__img').addClass('is-show');
    });
  }
});

// swiper
const mainview_swiper = new Swiper('.mainview__swiper', {
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  speed: 1000,
  loop: true,
  effect: 'fade',
  slidesPerView: 1,
  pagination: {
    el: '.mainview__pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<div class="dot ' +
        className +
        '">' +
        '<svg><circle  cx="50%" cy="50%" r="50%" /></div>'
      );
    },
  },
});

const pickup_swiper = new Swiper('.pickup__swiper', {
  autoplay: {
    delay: 3000,
  },
  loop: true,
  slidesPerView: 1.8,
  spaceBetween: 15,
  breakpoints: {
    768: {
      slidesPerView: 4.2,
      spaceBetween: 28,
    },
  },
  pagination: {
    el: '.pickup__pagination',
    type: 'custom',
    renderCustom: (swiper, current, total) => {
      return ('0' + current).slice(-2) + ' / ' + ('0' + total).slice(-2);
    },
  },
  navigation: {
    nextEl: '.pickupNext',
    prevEl: '.pickupPrev',
  },
});

const bannerSwiper = new Swiper('.banner__swiper', {
  autoplay: true,
  loop: true,
  slidesPerView: 2.2,
  spaceBetween: 15,
  breakpoints: {
    768: {
      slidesPerView: 5,
      spaceBetween: 28,
    },
  },
  navigation: {
    nextEl: '.bannerNext',
    prevEl: '.bannerPrev',
  },
});
